import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Container from '../../../../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import AccessGuard from '../../../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../../../Core/Authentication/ModuleAccess';
import { useGetCoreSystemCommunication } from '../../../CoreSystem.api';
import { TransferType } from '../../../../../../../Errands/Components/Sale.api';

const Communication = () => {
  const history = useHistory();
  const { id } = useParams();
  const { isLoading, communication, getCommunication } = useGetCoreSystemCommunication(id);
  useEffect(() => {
    async function loadCommunication() {
      await getCommunication();
    }
    loadCommunication();
  }, [getCommunication]);

  return (
    <Container
      form
      loading={isLoading}
      customSize={{fullsize: true}}
      actions={
        <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => history.push(`${history.location.pathname}/communication/update`)}
          >
            Redigera
          </Button>
        </AccessGuard>
      }
    >
      <Fieldset style={{marginBottom: '1rem'}}>
        <InputField label="Larmcentral" value={communication?.alarmCenterName} viewOnly />
        <InputField label="Format" value={communication?.format?.name} viewOnly />
        <InputField customWidth={6} label="Överföring (avtal)" type="text" value={communication?.activeAgreementTransferType && TransferType[communication.activeAgreementTransferType]} viewOnly />
        <InputField customWidth={6} label="Sekundär överföring (avtal)" type="text" value={communication?.activeAgreementSecondaryTransferType && TransferType[communication.activeAgreementSecondaryTransferType]} viewOnly />
        <InputField customWidth={6} label="TCP/IP" type="text" value={communication?.tcPorIP} viewOnly />
        <InputField customWidth={6} label="MulticomNr" type="text" value={communication?.multicomNumber} viewOnly />
        <InputField fullwidth label="Sändar-ID" type="text" value={communication?.transmitter} viewOnly />
        <InputField
          label="Info till Larmcentral"
          multiline
          rows={5}
          value={communication?.alarmCenterInformation}
          viewOnly
          fullwidth
        />
      </Fieldset>
      <Fieldset style={{marginBottom: '1rem'}}>
        <InputField label="Vaktbolag" value={communication?.securityCompanyName} viewOnly />
        <InputField label="Nyckel ID" type="text" value={communication?.keyNumber} viewOnly />
        <InputField
          label="Info till Vaktbolag"
          multiline
          rows={5}
          value={communication?.securityCompanyInformation}
          viewOnly
          fullwidth
        />
      </Fieldset>
      <Fieldset style={{marginBottom: '1rem'}}>
        <InputField label="Telematik-leverantör" value={communication?.telematicSupplierName} viewOnly />
        <InputField label="Telematik-ID" type="text" value={communication?.gsMorSNR} viewOnly />
        <InputField
          label="Info telematik"
          multiline
          rows={5}
          value={communication?.telematicInformation}
          viewOnly
          fullwidth
        />
      </Fieldset>
      <InputField
        label="Interna anteckningar"
        multiline
        rows={5}
        value={communication?.otherInformation}
        viewOnly
        fullwidth
      />
    </Container>
  );
};

export default Communication;
