import React from 'react';
import { TableRow, TableCell, TableBody, TableHead, Checkbox } from '@material-ui/core';
import { Table } from '../../../../../../../Shared/Table/Table';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import { ISaleChecklistEntryDto, ISaleProductDto, SaleChecklistEntryType } from '../../../Sale.api';
import useStyles from './Checklist.styles';

interface IChecklistProps {
  checklistEntries: ISaleChecklistEntryDto[];
  products: ISaleProductDto[] | undefined;
  onChange: (entries: ISaleChecklistEntryDto[]) => void;
  disabled?: boolean;
}

const checklistItems = [
  { type: SaleChecklistEntryType.CentralEquipment, name: 'Centralutrustning' },
  { type: SaleChecklistEntryType.PowerSupply, name: 'Strömförsörjning' },
  { type: SaleChecklistEntryType.Actuators, name: 'Manöverdon' },
  { type: SaleChecklistEntryType.Detectors, name: 'Detektorer' },
  { type: SaleChecklistEntryType.Sounders, name: 'Larmdon' },
  { type: SaleChecklistEntryType.Transfer, name: 'Överföring' },
  { type: SaleChecklistEntryType.TFConnectionControl, name: 'T/F-kopplingskontroll' },
];

const Checklist = (props: IChecklistProps) => {
  const classes = useStyles();
  const { checklistEntries, onChange, products, disabled } = props;

  function updateEntry(type: SaleChecklistEntryType, installed: boolean | undefined, tested: boolean | undefined, done: boolean | undefined, comment: string | undefined, saleProductId: number | undefined) {
    const oldEntry = type === SaleChecklistEntryType.SaleProduct && saleProductId !== undefined ? checklistEntries.find((x) => x.saleProductId === saleProductId) : checklistEntries.find((x) => x.type === type);
    const newList = type === SaleChecklistEntryType.SaleProduct && saleProductId !== undefined ? [...checklistEntries.filter((x) => x.saleProductId !== saleProductId)] : [...checklistEntries.filter((x) => x.type !== type)];

    const newEntry: ISaleChecklistEntryDto = oldEntry ? { ...oldEntry } : { type, installed: !!installed, tested: !!tested, done: !!done, saleProductId, comment };
    if (installed !== undefined) newEntry.installed = installed;
    if (tested !== undefined) newEntry.tested = tested;
    if (done !== undefined) newEntry.done = done;
    if (comment !== undefined) newEntry.comment = comment;
    if (saleProductId !== undefined) newEntry.saleProductId = saleProductId;
    newList.push(newEntry);

    onChange(newList);
  }

  return (
    <Table customSize={{ fullsize: true }}>
      <TableHead>
        <TableRow>
          <TableCell>Namn</TableCell>
          <TableCell align="center">Installerad</TableCell>
          <TableCell align="center">Testad</TableCell>
          <TableCell align="center">OK</TableCell>
          <TableCell>Kommentar</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {checklistItems.map((item) => {
          const entry = checklistEntries.find((x) => x.type === item.type);
          return (
            <TableRow key={item.type}>
              <TableCell>{item.name}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.checkbox}>
                <Checkbox
                  disabled={disabled}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={!!entry?.done}
                  onChange={(_, checked) => updateEntry(item.type, undefined, undefined, checked, undefined, undefined)}
                />
              </TableCell>
              <TableCell>
                <InputField
                  viewOnly={disabled}
                  aria-label="Kommentar"
                  value={entry?.comment ?? ''}
                  onChange={(e) => updateEntry(item.type, undefined, undefined, undefined, e.target.value, undefined)}
                />
              </TableCell>
            </TableRow>
          );
        })}
        {products?.map((product) => {
          const entry = checklistEntries.find((x) => x.saleProductId === product.id);
          return (
            <TableRow key={product.id}>
              <TableCell>{`${product.name} ${product.facilityPlacement}`}</TableCell>
              <TableCell className={classes.checkbox}>
                <Checkbox
                  disabled={disabled}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={!!entry?.installed}
                  onChange={(_, checked) => updateEntry(SaleChecklistEntryType.SaleProduct, checked, undefined, undefined, undefined, product.id)}
                />
              </TableCell>
              <TableCell className={classes.checkbox}>
                <Checkbox
                  disabled={disabled}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={!!entry?.tested}
                  onChange={(_, checked) => updateEntry(SaleChecklistEntryType.SaleProduct, undefined, checked, undefined, undefined, product.id)}
                />
              </TableCell>
              <TableCell className={classes.checkbox}>
                <Checkbox
                  disabled={disabled}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={!!entry?.done}
                  onChange={(_, checked) => updateEntry(SaleChecklistEntryType.SaleProduct, undefined, undefined, checked, undefined, product.id)}
                />
              </TableCell>
              <TableCell>
                <InputField
                  viewOnly={disabled}
                  aria-label="Kommentar"
                  value={entry?.comment ?? ''}
                  onChange={(e) => updateEntry(SaleChecklistEntryType.SaleProduct, undefined, undefined, undefined, e.target.value, product.id)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
export default Checklist;
