import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  useTheme,
  ListItemIcon,
  Typography,
  MenuItem,
  TableHead,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import useTable from '../../../../../../Shared/Table/useTable';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { formatDateWithTime } from '../../../../../../Shared/Formatting/formatDate';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import { Table } from '../../../../../../Shared/Table/Table';
import {
  useGetMeetingNotesList,
  useDeleteMeetingNotes,
  IMeetingNotesListType,
  IMeetingListItem,
} from '../MeetingNotes.api';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';

const MeetingNotesList = (props: { label: string; mode: IMeetingNotesListType; refetchNo?: number }) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const basePath = history.location.pathname.replace(/\/\d+$/, '');
  const { isLoading, meetingNotes, getMeetingNotes } = useGetMeetingNotesList(props.mode);
  const { isLoading: isDeleteLoading, deleteMeetingNotes } = useDeleteMeetingNotes();

  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable(100, 'date', SortDirection.Descending, '');
  const [emptyMessage, setEmptyMessage] = useState('');

  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);
  const { setErrorMessage } = useErrorMessage();
  const classes = useStyles();

  const getList = useCallback(
    async function () {
      const response = await getMeetingNotes(paginatedRequest);

      if (response?.totalCount <= 0) {
        if (paginatedRequest.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Finns inga mötesfrågor');
        }
      }
    },
    [getMeetingNotes, paginatedRequest],
  );

  useEffect(() => {
    getList();
  }, [props.refetchNo, getList]);

  async function handleDelete() {
    if (confirmDeleteId) {
      const result = await deleteMeetingNotes(confirmDeleteId.toString());

      if (isErrorResponse(result)) {
        setErrorMessage({ message: 'Gick inte att ta bort då den används' });
        setConfirmDeleteId(null);
      } else {
        getList();
      }
    }
  }

  const handleTableClick = (note: IMeetingListItem) => {
    history.push(`${basePath}/${note.id}`);
  };

  return (
    <Table
      label={`${props.label} (${meetingNotes?.totalCount ?? 0})`}
      filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
      iconButtons={
        props.mode !== 'search' ? undefined : (
          <AccessGuard module={ModuleIdentifiers.Todo} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till möte"
              onClick={() => {
                history.push(`${basePath}/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        )
      }
      pagination={
        meetingNotes && (
          <TablePaginationWithDatasource
            datasource={meetingNotes}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: meetingNotes && meetingNotes.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      mobile={mobileView}
    >
      {mobileView ? (
        <>
          {meetingNotes?.items.map((note) => (
            <MobileTable
              key={note.id}
              label={note.title}
              link={`${basePath}/${note.id}`}
              confirmDeleteRow={{
                show: confirmDeleteId === note.id,
                onConfirm: handleDelete,
                onCancel: () => setConfirmDeleteId(null),
                loading: isDeleteLoading,
              }}
              action={
                <AccessGuard
                  module={ModuleIdentifiers.Todo}
                  accessRights={AccessRights.Full}
                  fallback={<TableCell className={classes.emptyColumn}></TableCell>}
                >
                  <DropdownCell mobile={mobileView}>
                    <MenuItem onClick={() => setConfirmDeleteId(note.id)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">Ta bort</Typography>
                    </MenuItem>
                  </DropdownCell>
                </AccessGuard>
              }
            >
              <MobileTableRow label="Beskrivning" value={note.title} />
              <MobileTableRow label="Datum" value={formatDateWithTime(note.date)} />
              <MobileTableRow label="Ansvarig" value={note.responsiblePerson} />
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={'sr-only'}>Knappar</span>
              </TableCell>
              <TableCell>
                Rubrik
                <SortButton
                  property="title"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Datum
                <SortButton
                  property="date"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Ansvarig
                <SortButton
                  property="responsiblePerson"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meetingNotes?.items.map((note) => (
              <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={note.id}>
                {confirmDeleteId !== note.id ? (
                  <>
                    <AccessGuard
                      module={ModuleIdentifiers.Todo}
                      accessRights={AccessRights.Full}
                      fallback={<TableCell></TableCell>}
                    >
                      <DropdownCell>
                        <MenuItem onClick={() => setConfirmDeleteId(note.id)}>
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta bort</Typography>
                        </MenuItem>
                      </DropdownCell>
                    </AccessGuard>
                    <TableCell onClick={() => handleTableClick(note)} className={classes.linkFont}>
                      {note.title}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(note)}>{formatDateWithTime(note.date)}</TableCell>
                    <TableCell onClick={() => handleTableClick(note)}>{note.responsiblePerson}</TableCell>
                  </>
                ) : (
                  <ConfirmDeleteOrArchiveRow
                    onConfirm={handleDelete}
                    onCancel={() => setConfirmDeleteId(null)}
                    colspan={4}
                    loading={isDeleteLoading}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default MeetingNotesList;
