import { useState } from 'react';
import React from 'react';
import { Button, Table, TableHead, TableRow, TableCell, TableBody, Input } from '@material-ui/core';
import {
  IRiskAnalysis,
  IRiskAnalysisSource,
  useUpdateRiskAnalysisSources,
  useUpdateRiskAnalysis,
  IRiskAnalysisUpdate,
} from '../../../../Modules/Errands/Components/Sale.api';
import { ViewField } from '../../../../../Shared/InputFields/InputField/ViewField';
import CompanyUserSelect from '../../../../Modules/Admin/Components/Users/UserSelect/CompanyUserSelect';
import { formatDateWithTime } from '../../../../../Shared/Formatting/formatDate';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import { ISelectOption, Select } from '../../../../../Shared/Select';
import { getSelectedOption, getSelectedValue } from '../../../utils';

interface IRiskAnalysisFormProps {
  onUpdate: () => void;
  onClose: () => void;
  riskAnalysis: IRiskAnalysis;
  companyName?: string;
  title?: string;
  editable?: boolean;
}
export interface IRiskLevelOption<T> {
  type: T;
  name: string;
}


export function MapRiskLevelOptions(options: IRiskLevelOption<any>[]): ISelectOption[] {
  return options.map((x) => ({ label: x.name, value: (x.type as number).toString() }));
}
export enum RiskLevel {
  låg = 0,
  medel = 1,
  hög = 2
}

export const RiskLevelLabels = {
  [RiskLevel.låg]: 'Låg',
  [RiskLevel.medel]: 'Medel',
  [RiskLevel.hög]: 'Hög'
}
export const riskLevels: IRiskLevelOption<RiskLevel>[] = [
  { type: RiskLevel.låg, name: 'Låg' },
  { type: RiskLevel.medel, name: 'Medel' },
  { type: RiskLevel.hög, name: 'Hög' },
];

const riskLevelOptions = MapRiskLevelOptions(riskLevels);

const RiskAnalysisForm: React.FC<IRiskAnalysisFormProps> = (props) => {
  const {updateRiskAnalysisSources} = useUpdateRiskAnalysisSources();
  const {updateRiskAnalysis} = useUpdateRiskAnalysis();
  const [companyUserId, setCompanyUserId] = useState<string>(props.riskAnalysis.companyUserId?.toString() ?? '0');
  const [createdById, setCreatedById] = useState<string>(props.riskAnalysis.createdById?.toString() ?? '0');
  const [verifiedById, setVerifiedById] = useState<string>(props.riskAnalysis.verifiedById?.toString() ?? '0');
  const [riskAnalysisSources, changeRiskAnalysisSources] = useState<IRiskAnalysisSource[]>(props.riskAnalysis.riskSources ?? []);
  const { user } = useUserContext();
  
  async function updateMeasure(rs: IRiskAnalysisSource) {
    var sourceArray = {
      riskAnalysisSources: [
        { id: rs.id.toString() }
      ]
    };
    if (props.riskAnalysis && props.riskAnalysis.saleId && props.riskAnalysis.id) {
      const response = await updateRiskAnalysisSources(sourceArray, props.riskAnalysis?.saleId?.toString(), props.riskAnalysis.id.toString())
      if (response != null) {
        props.onClose();
      }
    }
  }
  async function updateAnalysis() {;
    var payload: IRiskAnalysisUpdate = {
      companyUserId: parseInt(companyUserId),
      createdById: parseInt(createdById),
      verifiedById: parseInt(verifiedById),
      riskAnalysisSources: riskAnalysisSources ?? []
    }
    
    if (props.riskAnalysis && props.riskAnalysis.saleId && props.riskAnalysis.id) {
      const response = await updateRiskAnalysis(payload, props.riskAnalysis?.saleId?.toString(), props.riskAnalysis.id.toString())
      if (response != null) {
        props.onUpdate();
      }
    }
  }


  return (
    <DialogForm
      CustomWidth={80}
      actions={
        <>
          {props.editable &&
            <Button variant="outlined" onClick={() => changeRiskAnalysisSources([...riskAnalysisSources, {
              id: 0,
              companyUserId: 0,
              measure: '',
              source: '',
              riskLevel: 0
            }])}>Lägg till riskkälla</Button>
          }
          <Button variant="outlined" onClick={props.onClose}>
            Stäng
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => updateAnalysis()}
            data-testid="saveriskanalysis"
          >
            Spara riskanalys
          </Button>
        </>
      }              
      children={
        <>
        <Fieldset>
          <ViewField customWidth={6} label='Tillhör projektet' value={props.title} />
          <ViewField customWidth={6} label='Dokumentnamn' value="Riskbedömning & handlingsplan" />

          <ViewField customWidth={6} label='Företag' value={props.companyName ?? ''} />
          <ViewField customWidth={3} label='Datum' value={new Date().toDateString()} />
          <ViewField customWidth={3} label='Version' value='-' />

          <CompanyUserSelect 
            label="Deltagare" 
            selectedCompanyUserId={companyUserId.toString()}
            onChange={(option) => option && setCompanyUserId(option)}
            customWidth={6}
            isDisabled={props.editable === false}
          />
          <CompanyUserSelect 
            label="Framtagen av" 
            onChange={(option) => option && setCreatedById(option)} 
            selectedCompanyUserId={createdById}
            customWidth={3}
            isDisabled={props.editable === false}
          />
          <CompanyUserSelect 
            label="Godkänd av" 
            onChange={(option) => option && setVerifiedById(option)} 
            selectedCompanyUserId={verifiedById} 
            customWidth={3}
            isDisabled={props.editable === false}
          />
        </Fieldset>
        <Table>
          <TableHead>
            <TableRow>            
              <TableCell>
                Riskkälla
              </TableCell>
              <TableCell>
                Riskbedömning
              </TableCell>
              <TableCell>
                Åtgärd
              </TableCell>
              <TableCell>
                Ansvarig
              </TableCell>
              <TableCell>
                Klar.
              </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {riskAnalysisSources?.map((rs, index) => {
                return(
                  <TableRow>
                    <TableCell>
                      <InputField
                        viewOnly={rs.completedTime && rs.completedTime !== null}
                        type="text"
                        value={rs.source}
                        onChange={(val) => {
                          const _riskAnalysisSources = JSON.parse(JSON.stringify(riskAnalysisSources));
                          _riskAnalysisSources[index].source = val.target.value;
                          changeRiskAnalysisSources(_riskAnalysisSources);
                        }}
                        required
                        fullwidth
                      />
                    </TableCell>
                    <TableCell>
                    <Select
                      inputFieldProps={{ customWidth: 12, viewOnly: !props.editable || (rs.completedTime && rs.completedTime !== null) }}
                      selectProps={{
                        value: getSelectedOption(riskLevelOptions, rs.riskLevel),
                        options: riskLevelOptions,
                        onChange: (selectedValue) => {
                          const _riskAnalysisSources = JSON.parse(JSON.stringify(riskAnalysisSources));
                          _riskAnalysisSources[index].riskLevel = getSelectedValue(selectedValue)
                          changeRiskAnalysisSources(_riskAnalysisSources);
                        },
                      }}
                    /></TableCell>
                    <TableCell>
                      <InputField
                        viewOnly={!props.editable || (rs.completedTime && rs.completedTime !== null)}
                        type="text"
                        value={rs.measure}
                        onChange={(val) => {
                          const _riskAnalysisSources = JSON.parse(JSON.stringify(riskAnalysisSources));
                          _riskAnalysisSources[index].measure = val.target.value;
                          changeRiskAnalysisSources(_riskAnalysisSources);
                        }}
                        required
                        fullwidth
                      />
                    </TableCell>
                    <TableCell style={{minWidth: '100px'}}>
                      <CompanyUserSelect 
                        fullwidth 
                        onChange={(option) => {
                          const _riskAnalysisSources = JSON.parse(JSON.stringify(riskAnalysisSources));
                          _riskAnalysisSources[index].companyUserId = option;
                          changeRiskAnalysisSources(_riskAnalysisSources);
                        }}
                        selectedCompanyUserId={rs.companyUserId?.toString()} 
                        isDisabled={!props.editable || (rs.completedTime && rs.completedTime !== null)} />
                    </TableCell>
                    <TableCell style={{minWidth: '100px'}}>
                    {(rs.completedTime != null ? formatDateWithTime(rs.completedTime) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            updateMeasure(rs);
                          }}
                          disabled={user.selectedUserId !== rs.companyUserId}
                        >
                          Slutför
                        </Button>
                      ))}
                      </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
        </Table>
        </>
    } />
  );
};

export default RiskAnalysisForm;
