import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../../../../../../Shared/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../../../../../../../Shared/Container/Container';
import Select, { ISelectOption } from '../../../../../../../../Shared/Select/Select';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import CustomDocument from '../DocumentTypes/CustomDocument/CustomDocument';
import Letter from '../DocumentTypes/Letter/Letter';
import OrderSecurityCompany from '../DocumentTypes/OrderSecurityCompany/OrderSecurityCompany';
import OrderEmergencyCenter from '../DocumentTypes/OrderEmergencyCenter/OrderEmergencyCenter';
import InspectionProtocol from '../DocumentTypes/InspectionProtocol/InspectionProtocol';
import FacilityCertificate from '../DocumentTypes/FacilityCertificate/FacilityCertificate';
import FacilitySafetyCertificate from '../DocumentTypes/FacilitySafetyCertificate/FacilitySafetyCertificate';
import { IDocumentFormProps } from '../IDocumentFormProps';
import useInputState from '../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useFormValidation from '../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { DocumentType, DocumentTypes, getDocumentTypeName, useOpenDocument } from '../Documents.api';
import { ICustomerDto, useGetCustomer } from '../../../../../Customers.api';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../../../../Styles/Theme';
import CustomerInfoDisplay from '../../../../../Components/ViewCustomer/CustomerInfo/CustomerInfoDisplay';
import UploadDocumentForm from '../DocumentTypes/UploadDocumentForm/UploadDocumentForm';
import { IFacility, useGetFacility } from '../../../../Facilities/Facilities.api';
import SectionList from '../DocumentTypes/SectionList/SectionList';

interface ITypeSelectOption extends ISelectOption {
  type: DocumentType;
}

const switchSelectOptions: ISelectOption[] = [
  {
    label: 'Skapa nytt dokument',
    value: '1',
  },
  {
    label: 'Ladda upp dokument',
    value: '2',
  },
];

const defaultOptions: ITypeSelectOption[] = DocumentTypes.map((x) => ({
  label: x.name,
  value: x.name,
  type: x.type,
})).sort((a,b) => a.label < b.label ? -1 : 1);

const optionsWithoutCommunication: ITypeSelectOption[] = DocumentTypes.filter((x) => !x.communicationRequired).map(
  (x) => ({
    label: x.name,
    value: x.name,
    type: x.type,
  }),
);

const optionsWithoutFacility: ITypeSelectOption[] = DocumentTypes.filter((x) => !x.facilityRequired).map((x) => ({
  label: x.name,
  value: x.name,
  type: x.type,
}));

const optionsFacilityWithoutAgreement: ITypeSelectOption[] = DocumentTypes.filter(
  (x) => x.facilityRequired && !x.agreementRequired,
).map((x) => ({
  label: x.name,
  value: x.name,
  type: x.type,
}));

const customerDocumentOptions = (customer: ICustomerDto | undefined) => {
  if (customer && customer.facilities.length) {
    if (customer.facilities.some((x) => x.hasCommunication)) {
      return defaultOptions;
    }
    return optionsWithoutCommunication;
  }
  return optionsWithoutFacility;
};

const facilityDocumentOptions = (facility: IFacility) => {
  if (facility.coreSystems.some((x) => x.activeAgreement && x.communication !== null)) {
    return defaultOptions;
  }

  if (facility.coreSystems.some((x) => x.activeAgreement && x.communication === null)) {
    return optionsWithoutCommunication;
  }

  return optionsFacilityWithoutAgreement;
};

const DocumentForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { isFormValid, formRef } = useFormValidation();
  const { customerId, facilityId } = useParams<{ customerId: string; facilityId?: string }>();
  const { isLoading: isOpenLoading, openDocument } = useOpenDocument(customerId);
  const { getCustomer, isLoading: isGetCustomerLoading } = useGetCustomer();
  const { getFacility, facility } = useGetFacility(customerId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentName, changeDocumentName, setDocumentName] = useInputState('');
  const [save, setSave] = useState<() => void>();
  const [selectedDocumentOption, setSelectedDocumentOption] = useState<string>();
  const [selectedType, setSelectedType] = useState<DocumentType>();
  const [customer, setCustomer] = useState<ICustomerDto>();
  const [isStateValid, setIsStateValid] = useState(true);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isAllValid = useMemo(() => {
    return isFormValid && isStateValid;
  }, [isFormValid, isStateValid]);

  const options = useMemo(() => {
    if (!facility) return customerDocumentOptions(customer);

    return facilityDocumentOptions(facility);
  }, [facility, customer]);

  const loadCustomer = useCallback(async () => {
    if (customerId) {
      const result = await getCustomer(customerId + '/false');
      setCustomer(result);
    }
  }, [customerId, getCustomer]);

  useEffect(() => {
    async function getCustomerFacility() {
      getFacility(facilityId);
    }
    if (facilityId) {
      getCustomerFacility();
    }
  }, [facilityId, getFacility]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [selectedType]);

  useEffect(() => {
    loadCustomer();
  }, [loadCustomer]);

  function Actions() {
    const cancel = () => history.push(`/customers/${customerId}`);
    return (
      <>
        <Button
          color="default"
          variant="outlined"
          disabled={isLoading}
          onClick={
            selectedType !== undefined
              ? () => {
                  setSelectedType(undefined);
                  setIsStateValid(true);
                }
              : cancel
          }
        >
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={
            isLoading ||
            !isAllValid ||
            selectedDocumentOption === undefined ||
            (selectedDocumentOption === '1' && selectedType === undefined)
          }
          loading={isOpenLoading}
          onClick={save}
        >
          {selectedDocumentOption === "1"  ? 'Skapa' : 'Ladda upp'}
        </Button>
      </>
    );
  }

  const onDocumentCreated = useCallback(
    (documentId: number) => {
      if (selectedType && selectedType !== DocumentType.Letter && selectedType !== DocumentType.CustomDocument) {
        openDocument(documentId);
      }
      history.goBack();
    },
    [selectedType, history, openDocument],
  );

  const saveCallback = useCallback((callback: () => void) => {
    setSave(() => callback);
  }, []);

  const props: IDocumentFormProps = {
    customerId: customerId,
    facilityId: facilityId,
    onLoadingChange: setIsLoading,
    documentName,
    saveCallback: saveCallback,
    onDocumentCreated: onDocumentCreated,
    validateFormState: setIsStateValid,
    customerInfo: customer,
  };

  const getForm = () => {
    if (selectedDocumentOption === '2') return <UploadDocumentForm {...props} />;
    switch (selectedType) {
      case DocumentType.Letter:
        return <Letter {...props} />;
      case DocumentType.OrderSecurityCompany:
        return <OrderSecurityCompany {...props} />;
      case DocumentType.OrderEmergencyCenter:
        return <OrderEmergencyCenter {...props} />;
      case DocumentType.InspectionProtocol:
        return <InspectionProtocol {...props} />;
      case DocumentType.FacilityCertificate:
        return <FacilityCertificate {...props} />;
      case DocumentType.SectionList:
        return <SectionList {...props} />;
      case DocumentType.FacilitySafetyCertificate:
        return <FacilitySafetyCertificate {...props} />;
      case DocumentType.CustomDocument:
        return <CustomDocument {...props} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {smDown && <CustomerInfoDisplay customerId={customerId} />}
      <Container
        form
        ref={formRef}
        label={selectedType !== undefined ? 'Skapa ' + props.documentName.toLocaleLowerCase() : 'Nytt dokument'}
        loading={isLoading || isGetCustomerLoading}
        actions={<Actions />}
      >
        <Select
          inputFieldProps={{ label: 'Välj skapa eller ladda upp', required: true }}
          selectProps={{
            value:
              selectedDocumentOption !== undefined
                ? switchSelectOptions.find((x) => x.value === selectedDocumentOption)
                : null,
            options: switchSelectOptions,
            onChange: (selectedValue) => {
              setSelectedDocumentOption((selectedValue as ISelectOption).value);
              setSelectedType(undefined);
            },
          }}
        />
        {selectedDocumentOption === '1' && selectedType === undefined && (
          <Select
            inputFieldProps={{ label: 'Typ av dokument', required: true }}
            selectProps={{
              value: selectedType !== undefined ? options.find((x) => x.type === selectedType) : null,
              options: options,
              onChange: (selectedValue) => {
                var docType = (selectedValue as ITypeSelectOption).type;
                setSelectedType(docType);
                setDocumentName(getDocumentTypeName(docType) || '');
              },
            }}
          />
        )}
        {selectedDocumentOption === '1' && selectedType === undefined && (
          <InputField
            label="Dokumentets namn"
            required
            type="text"
            value={documentName}
            onChange={changeDocumentName}
          />
        )}
        {selectedDocumentOption !== undefined && getForm()}
      </Container>
      {!smDown && <CustomerInfoDisplay customerId={customerId} />}
    </>
  );
};

export default DocumentForm;
