import React, { useCallback, useEffect, useState } from 'react'; //find some query to get to do list
import WidgetContainer from '../../../../../Shared/WidgetContainer/WidgetContainer';
import { useGetTodos, ITodoListItem, useArchiveTodo, TodoRole } from '../../../../Modules/Todos/Todos.api';
import { Typography } from '@material-ui/core';
import ToDoListWidgetMenu from './Component/ToDoListWidgetMenu';
import { isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import AddToDo from './Component/AddToDo/AddToDo';
import { formatDateWithTime } from '../../../../../Shared/Formatting/formatDate';
import { useHistory } from 'react-router';
import { useStyles } from './ToDoListWidget.styles';
import moment from 'moment';
import { getCalendarEntryTypeName, ICalendarEntry, useGetMyCalendar } from '../../../Booking/Booking.api';
import { ICalendarEvent } from '../../../../Shared/Calendars/UserCalendar/UserCalendar';
import { SaleStatus, SaleStatusLabel } from '../Sale.api';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import { Role } from '../../../Admin/Components/Users/Users.api';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import Archive from '@material-ui/icons/ArchiveOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import TodoForm from '../../../Todos/Components/TodoForm/TodoForm';
import PopperMenu from '../../../../../Shared/Popper/Popper';

const addSecondToDate = (date: Date) => {
  // A hack to prevent no duration event to be counted as allDay event
  var newDate = new Date(date);
  newDate.setSeconds(1);
  return newDate;
};

const ToDoListWidget = () => {
  const classes = useStyles();
  const { getMyCalendar } = useGetMyCalendar();
  const [events, setEvents] = useState<ICalendarEvent<ICalendarEntry>[]>();
  const { isLoading, todos, getTodos } = useGetTodos();
  const { archiveTodo } = useArchiveTodo();
  const { setErrorMessage } = useErrorMessage();
  const [openTodo, setOpenTodo] = useState<number>(0);
  const [openTodoPopup, setopenTodoPopup] = useState<boolean>(false);
  const [stateTodoId, setTodoId] = useState<string>('');
  const history = useHistory();
  const { user } = useUserContext();

  const loadData = useCallback(async () => {
    await getTodos({
      pageNumber: 1,
      pageSize: 25,
      sortProperty: '',
      sortDirection: 0,
      query: '',
      inArchive: false,
      getWithinWeek: true,
    });
    const startDate = moment(Date.now()).subtract(3, 'month').startOf('day').toDate();
    const endDate = moment(Date.now()).endOf('day').toDate();

    var userCalendar = await getMyCalendar({ startDate, endDate });

    if (userCalendar && Array.isArray(userCalendar)) {
      var saleStatuses = user?.roles?.some((r) => r === Role.Installer)
        ? [SaleStatus.installationBooked]
        : [SaleStatus.installationBooked, SaleStatus.installed];
      setEvents(
        userCalendar
          .filter((x) => x.saleStatus && saleStatuses.includes(x.saleStatus))
          .map((x) => ({
            start: new Date(x.startDate),
            end: addSecondToDate(x.endDate),
            title: x.description ? x.description : getCalendarEntryTypeName(x.type),
            resource: x,
            type: x.type,
            description: x.description,
            customerName: x.customerName,
            id: x.id,
          })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTodos, getMyCalendar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function handleArchive(toDoId: string) {
    if (toDoId) {
      const result = await archiveTodo(toDoId);

      if (isErrorResponse(result)) {
        setErrorMessage({ message: result.message });
      } else {
        loadData();
      }
    }
  }

  function goToEdit(toDoId: string) {
    setTodoId(toDoId);
    setopenTodoPopup(!openTodoPopup);
  }

  function setOpenPopup() {
    setopenTodoPopup(!openTodoPopup);
  }

  function goToOrder(orderId: string) {
    history.push(`/errands/orders/${orderId}`);
  }

  function isTodoOverDue(todo: ITodoListItem) {
    if (moment(todo.deadline).diff(new Date(), 'day') < 0) return true;
  }

  function getClasses(todo: ITodoListItem) {
    if (isTodoOverDue(todo)) {
      return classes.redContainer;
    }
    if (todo.role === TodoRole.Assignor) {
      return classes.greenContainer;
    }
    if (todo.role === TodoRole.Assignee) {
      return classes.blueContainer;
    }
    return classes.greyContainer;
  }

  function getTodoIcon(todo: ITodoListItem) {
    if (todo.requiredAnswer) {
      if (todo.answer) {
        if (todo.role === TodoRole.Assignor) {
          return <MarkunreadMailboxIcon />;
        }
        if (todo.role === TodoRole.Assignee) {
          return <CheckIcon />;
        }
      } else {
        if (todo.role === TodoRole.Assignor) {
          return <ArrowForwardIcon />;
        }
        if (todo.role === TodoRole.Assignee) {
          return <ArrowBackIcon />;
        }
      }
    }
  }

  return (
    <>
      <WidgetContainer
        loading={isLoading}
        label={`Uppgift (${todos?.totalCount ?? 0})`}
        actions={<AddToDo onAction={loadData} />}
        headerLink="/todos/list"
      >
        <ul className={`${classes.list} ${classes.todoList}`}>
          {todos &&
            todos.items.length > 0 &&
            todos.items
              .sort((a, b) => b.id - a.id)
              .filter((item, index) => index < 10 ?? item)
              .map((todo) => (
                <li key={todo.id} className={classes.listItem}>
                  <div className={`${classes.messageListContainer} ${getClasses(todo)}`}>
                    {openTodo === todo.id ? (
                      <>
                        <div className={classes.message} onClick={() => goToEdit(todo.id.toString())}>
                          {todo.created && (
                            <Typography>
                              <span className={classes.bold}>{'Id: '}</span>
                              <span>
                                {todo.id} - {formatDateWithTime(todo.created)}
                              </span>
                            </Typography>
                          )}
                          {todo.role === TodoRole.Assignor && todo.assignee ? (
                            <Typography>
                              <span className={classes.bold}>Till: </span>
                              {todo.assignee} - {formatDateWithTime(todo.sendDate)}
                            </Typography>
                          ) : (
                            todo.role === TodoRole.Assignee && (
                              <Typography>
                                <span className={classes.bold}>Från: </span>
                                {todo.assignor} - {formatDateWithTime(todo.sendDate)}
                              </Typography>
                            )
                          )}
                          <Typography>
                            <span className={classes.bold}>{'Deadline: '}</span>
                            <span className={isTodoOverDue(todo) ? classes.warningColor : classes.normalColor}>
                              {formatDateWithTime(todo.deadline)}
                            </span>
                          </Typography>
                          <Typography>{todo.description}</Typography>
                          {todo.requiredAnswer && (
                            <Typography>
                              <span className={classes.bold}>{'Svar: '}</span>
                              <span>
                                {todo.answer ?? '-'} - {formatDateWithTime(todo.answerDate)}
                              </span>
                            </Typography>
                          )}
                        </div>
                        <AccessGuard module={ModuleIdentifiers.Todo} accessRights={AccessRights.Full}>
                          <span title="Arkivera" onClick={() => todo.canBeArchived && handleArchive(todo.id.toString())} style={{ cursor: 'pointer', opacity: todo.canBeArchived ? 1 : 0.5 }}>
                            <Archive />
                          </span>
                          <span title="Redigera" onClick={() => goToEdit(todo.id.toString())} style={{ cursor: 'pointer', marginLeft: '10px'}}>
                            <Edit />
                          </span>   
                        </AccessGuard>
                      </>
                    ) : (
                      <div className={classes.message} onClick={() => setOpenTodo(todo.id)}>
                        <Typography>
                          {todo.assignor}: {todo.description}
                        </Typography>
                        {getTodoIcon(todo)}
                      </div>
                    )}
                  </div>
                </li>
              ))}
          {todos && todos.items.length > 10 && (
            <span
              onClick={() => history.push('/todos/list')}
              style={{ cursor: 'pointer', width: '100%', textAlign: 'center', display: 'block', listStyle: 'none' }}
            >
              Se alla uppgifter
            </span>
          )}
        </ul>
        <ul className={classes.list}>
          {events?.map((event: ICalendarEvent<ICalendarEntry>) => (
            <li key={event.resource.id} className={classes.eventListItem}>
              <div
                className={`${classes.messageListContainer} ${
                  moment(event.resource.endDate) < moment(Date.now()) && classes.redContainer
                }`}
                onClick={() => event.resource.saleId && goToOrder(event.resource.saleId?.toString())}
              >
                <div className={classes.message}>
                  <Typography>
                    <span className={classes.bold}>
                      <span>
                        {`Bokning ${event.resource.saleId}  - ${event.resource.customerName} ${
                          event.resource.saleStatus && '(' + SaleStatusLabel[event.resource.saleStatus] + ')'
                        }`}
                      </span>
                    </span>
                  </Typography>
                  <Typography>
                    {event.resource.startDate ? moment(event.resource.startDate).format('YYYY-MM-DD HH:mm') : ''}
                    {event.resource.endDate ? ' - ' + moment(event.resource.endDate).format('HH:mm') : ''}
                  </Typography>
                  <Typography className={classes.eventListInformation}>{event.resource.description}</Typography>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </WidgetContainer>
      {openTodoPopup && (
        <PopperMenu
          setOpen={setOpenPopup}
          open={openTodoPopup}
          onClose={() => setOpenPopup()}
          buttonType="icon"
          customContent
          alwaysDialog
          title={'Lägg till uppgift'}
          content={
            <TodoForm
              id={stateTodoId}
              isModal
              onAction={() => {
                loadData();
                setOpenPopup();
              }}
            />
          }
        />
      )}
    </>
  );
};

export default ToDoListWidget;
