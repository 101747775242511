import { useCallback } from 'react';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from '../../../../../../../Core/Api/Api';
import IPaginatedRequest from '../../../../../../../Shared/Api/request/IPaginatedRequest';
import { ICreateResult } from '../../../../../../../Shared/Api/response/ICreateResult';
import { IFileResult, openFile } from '../../../../../../../Shared/Api/response/IFileResult';
import IPaginatedResponse from '../../../../../../../Shared/Api/response/IPaginatedResponse';
import ValidationErrorResultType from '../../../../../../../Shared/Api/response/ValidationErrorResultType';
import { IUploadFile } from '../../../../../../../Shared/InputFields/UploadInput/UploadInput';

export enum DocumentType {
  Agreement,
  AgreementTermination,
  ContactListAndResponseTimes,
  FacilityCertificate,
  FacilitySafetyCertificate,
  InspectionProtocol,
  Letter,
  OrderEmergencyCenter,
  OrderSecurityCompany,
  CustomDocument,
  Picture,
  Offer,
  Order,
  Upload,
  Invoice,
  SectionList
}

export const DocumentTypes = [
  { type: DocumentType.Agreement, name: 'Avtal', facilityRequired: true },
  { type: DocumentType.FacilityCertificate, name: 'Anläggarintyg', facilityRequired: true, agreementRequired: true },
  {
    type: DocumentType.FacilitySafetyCertificate,
    name: 'Anläggarintyg SBSC',
    facilityRequired: true,
    agreementRequired: true,
  },
  { type: DocumentType.InspectionProtocol, name: 'Besiktningsprotokoll', facilityRequired: false },
  { type: DocumentType.Letter, name: 'Brev', facilityRequired: false, agreementRequired: false },
  {
    type: DocumentType.OrderSecurityCompany,
    name: 'Beställning Vaktbolag',
    facilityRequired: true,
    agreementRequired: true,
    communicationRequired: true,
  },
  {
    type: DocumentType.OrderEmergencyCenter,
    name: 'Beställning Larmcentral',
    facilityRequired: true,
    agreementRequired: true,
    communicationRequired: true,
  },
  { type: DocumentType.CustomDocument, name: 'Anpassat dokument', facilityRequired: true },
  { type: DocumentType.Picture, name: 'Bild', facilityRequired: false },
  { type: DocumentType.Offer, name: 'Offert', facilityRequired: false },
  { type: DocumentType.Order, name: 'Order', facilityRequired: false },
  { type: DocumentType.Upload, name: 'Uppladdning', facilityRequired: false },
  { type: DocumentType.Invoice, name: 'Faktura', facilityRequired: false },
  { type: DocumentType.SectionList, name: 'Sektionsförteckning', facilityRequired: false },
];

export function getDocumentTypeName(type: DocumentType) {
  return DocumentTypes.find((x) => x.type === type)?.name;
}

export interface IUpdateDocument {
  fileName: string;
}

export interface IUpdateDocumentValidationErrors extends ValidationErrorResultType<IUpdateDocument> {}

export function useUpdateDocument() {
  const { isLoading, put } = useApiPut<IUpdateDocumentValidationErrors, IUpdateDocument>('');

  const update = useCallback(
    async (customerId: number, id: number, fileName: string) => {
      const command: IUpdateDocument = {
        fileName: fileName,
      };
      return put(command, `/api/customers/${customerId}/documents/${id}`);
    },
    [put],
  );

  return {
    IsUpdateDocumentLoading: isLoading,
    updateDocument: update,
  };
}

export interface IDocumentListItemDto {
  id: number;
  created: Date;
  type: DocumentType;
  fileName: string;
  saleId: number | undefined;
  createdBy: string;
  facilityName: string | null;
  edit: boolean;
}

interface IDocumentListRequeest extends IPaginatedRequest {
  facilityId?: string;
}

export function useGetCustomerDocuments(customerId: string | undefined, facilityId?: string) {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IDocumentListItemDto>, IDocumentListRequeest>(
    `/api/customers/${customerId}/documents`,
  );

  const getDocuments = useCallback(
    async (request: IDocumentListRequeest) => {
      return await post({ ...request, facilityId: facilityId });
    },
    [facilityId, post],
  );

  return {
    isLoading,
    documents: response,
    getDocuments,
  };
}

export function useOpenDocument(customerId: string | undefined) {
  const { isLoading, get } = useApiGet<IFileResult>(`/api/customers/${customerId}/documents/`, false);
  const openDocument = useCallback(
    async (documentId: number, downloadDirectly: boolean = false) => {
      async function loadDocument() {
        return await get(`${documentId}/content`);
      }
      openFile(await loadDocument(), downloadDirectly);
    },
    [get],
  );
  return { isLoading, openDocument };
}

export function useGetDocument(customerId: string | undefined) {
  const { isLoading, get } = useApiGet<IFileResult>(`/api/customers/${customerId}/documents/`, false);
  return { isLoading, getDocument: get };
}

export function useDeleteDocument(customerId: string) {
  const { isLoading, delete: deletePrior } = useApiDelete<{}>(`/api/customers/${customerId}/documents/`);

  const deleteDocument = async (documentId: string) => {
    await deletePrior(undefined, documentId);
  };

  return {
    isLoading,
    deleteDocument: deleteDocument,
  };
}

export interface IUploadDocumentCommand {
  facilityId?: number;
  document: IUploadFile;
}

export interface IUploadDocumentCommandValidationErrors extends ValidationErrorResultType<IUploadDocumentCommand> {}
export function useUploadDocument(customerId: string) {
  const { isLoading, post } = useApiPost<IUploadDocumentCommandValidationErrors, IUploadDocumentCommand>(
    `/api/customers/${customerId}/documents/upload`,
  );

  return {
    isLoading,
    uploadDocument: post,
  };
}

export enum SecurityChoice {
  ShellProtection,
  CompleteProtection,
  IntentionalProtection,
  VolumeProtection,
  PartialProtection,
  PointProtection,
  OutdoorDetection,
  Dimgenerator,
  SSF1042,
  MaintenanceAgreement,
  SSF1307,
}

export enum FacilityType {
  BurglarAlarm,
  NoiseAlarm,
  PortableAssaultAlarm,
  AssaultAlarm,
  FireIndication,
  InternalAlarm,
  ExternalAlarm,
}

export enum SecurityChoiceSBSC {
  ShellProtection,
  PointProtection,
  InternalAlarm,
  ExternalAlarm,
  IntentionalProtection,
  OutdoorDetection,
  VolumeProtection,
  Dimgenerator,
  PartialProtection,
  SSF1042,
}

export enum FacilityTypeSBSC {
  BurglarAlarm,
  NoiseAlarm,
  PortableAssaultAlarm,
  AssaultAlarm,
  FireIndication,
}

export enum PoliceSideType {
  AlarmClass3,
  IncompleteFacility,
  EquipmentFullyCertified,
  EquipmentPartiallyCertified,
  ChecksOnlyOnRenovations,
  DocumentationSubmitted,
  FacilityPlansOnAppendix,
}

export interface IPoliceDeviation {
  id: number | null;
  pos: string;
  note: string;
}

export interface IFacilityCertificateDocumentFormDto {
  receiver: string;
  facilityId: number;
  coreSystemId: number;
}

export function useGetFacilityCertificateDocumentForm(customerId: string, facilityId: string | undefined) {
  const { isLoading, response, get } = useApiGet<IFacilityCertificateDocumentFormDto>(
    `/api/customers/${customerId}/documents/facilitycertificateform/${facilityId}`,
    false,
  );

  return {
    isLoading,
    form: response,
    getFacilityCertificateDocumentForm: get,
  };
}

export interface ICreateFacilityCertificateDocumentCommand {
  documentName: string;
  receiver: string | null;
  certificateDate: Date;
  facilityId: number;
  buildings: string[];
  note: string | null;
  securityChoices: SecurityChoice[];
  alarmClass: string | null;
}

export interface ICreateFacilityCertificateDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateFacilityCertificateDocumentCommand> {}

export function useCreateFacilityCertificateDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateFacilityCertificateDocumentCommandValidationErrors>,
    ICreateFacilityCertificateDocumentCommand
  >(`/api/customers/${customerId}/documents/facilitycertificate`);

  return {
    isLoading,
    createFacilityCertificateDocument: post,
  };
}

export interface ISectionListDocumentFormDto {
  receiver: string;
  facilityId: number;
  coreSystemId: number;
}

export function useGetSectionListDocumentForm(customerId: string, facilityId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ISectionListDocumentFormDto>(
    `/api/customers/${customerId}/documents/sectionlistform/${facilityId}`,
    false,
  );

  return {
    isLoading,
    form: response,
    getSectionListDocumentForm: get,
  };
}

export interface ICreateSectionListDocumentCommand {
  documentName: string;
  receiver: string | null;
  certificateDate: Date;
  facilityId: number;
}

export interface ICreateSectionListDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateSectionListDocumentCommand> {}

export function useCreateSectionListDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateSectionListDocumentCommandValidationErrors>,
    ICreateSectionListDocumentCommand
  >(`/api/customers/${customerId}/documents/sectionlist`);

  return {
    isLoading,
    createSectionListDocument: post,
  };
}

export interface ICreateFacilitySafetyCertificateDocumentCommand {
  documentName: string;
  receiver: string | null;
  certificateDate: Date;
  facilityId: number;
  coreSystemId: number;
  buildings: string[];
  note: string | null;
  securityChoices: SecurityChoiceSBSC[];
  facilityTypes: FacilityTypeSBSC[];
  otherTransmission: string | null;
  externalAlarm: string | null;
  internalAlarm: string | null;
  testingDate: Date | null;
  facilityProjector: string | null;
  responsibleTechnician: string | null;
  testInterval: string | null;
  ssf130: string | null;
  alarmClass: string | null;
  nameClarification: string | null;
  maintenanceContractNumber: number | null;
  certificateNumber: number | null;
  policeSideOptions: PoliceSideType[];
  electricitySubcontractor: string | null;
  telecommunicationsSubcontractor: string | null;
  trainingCompleted: number | null;
  policeDeviations: IPoliceDeviation[];
}

export interface ICreateFacilitySafetyCertificateDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateFacilitySafetyCertificateDocumentCommand> {}

export function useCreateFacilitySafetyCertificateDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateFacilitySafetyCertificateDocumentCommandValidationErrors>,
    ICreateFacilitySafetyCertificateDocumentCommand
  >(`/api/customers/${customerId}/documents/facilitysafetycertificate`);

  return {
    isLoading,
    createFacilitySafetyCertificateDocument: post,
  };
}

export interface IInspectionProtocolDocumentFormDto {
  serialNumber: number;
  receiver: string;
}
export function useGetInspectionProtocolDocumentForm(customerId: string) {
  const { isLoading, response, get } = useApiGet<IInspectionProtocolDocumentFormDto>(
    `/api/customers/${customerId}/documents/inspectionprotocolform`,
    false,
  );

  return {
    isLoading,
    form: response,
    getInspectionProtocolDocumentForm: get,
  };
}

export interface ICreateInspectionProtocolDocumentCommand {
  documentName: string;
  receiver: string;
}

export interface ICreateInspectionProtocolDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateInspectionProtocolDocumentCommand> {}

export function useCreateInspectionProtocolDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateInspectionProtocolDocumentCommandValidationErrors>,
    ICreateInspectionProtocolDocumentCommand
  >(`/api/customers/${customerId}/documents/inspectionprotocol`);

  return {
    isLoading,
    createInspectionProtocolDocument: post,
  };
}

export interface ILetterDocumentFormDto {
  companyReference: string;
  customerName: string;
}

export function useGetLetterDocumentForm(customerId: string) {
  const { isLoading, response, get } = useApiGet<ILetterDocumentFormDto>(
    `/api/customers/${customerId}/documents/letterform`,
    false,
  );

  return {
    isLoading,
    form: response,
    getLetterDocumentForm: get,
  };
}

export interface ICreateLetterDocumentCommand {
  date: Date;
  customerReference: string;
  companyReference: string;
  letterText: string;
  facilityId?: string;
}

export interface ICreateLetterDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateLetterDocumentCommand> {}

export function useCreateLetterDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateLetterDocumentCommandValidationErrors>,
    ICreateLetterDocumentCommand
  >(`/api/customers/${customerId}/documents/letter`);

  return {
    isLoading,
    createLetterDocument: post,
  };
}

export interface IOrderEmergencyCenterDocumentFormDto {
  facilityId: number;
  coreSystemId: number;
  formatId: Number | undefined;
  takenFrom: string | null;
  agreementId: number | null;
  withoutAgreement: boolean;
}

export function useGetOrderEmergencyCenterDocumentForm(customerId: string) {
  const { isLoading, response, get } = useApiGet<IOrderEmergencyCenterDocumentFormDto>(
    `/api/customers/${customerId}/documents/orderemergencycenterform`,
    false,
  );

  return {
    isLoading,
    form: response,
    getOrderEmergencyCenterDocumentForm: get,
  };
}

export interface ICreateOrderEmergencyCenterDocumentCommand {
  documentName: string;
  facilityId: number;
  coreSystemId: number;
  formatId: Number | null;
  startDate: Date;
  takenFrom: string;
  agreementId: number | null;
  withoutAgreement: boolean;
}

export interface ICreateOrderEmergencyCenterDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateOrderEmergencyCenterDocumentCommand> {}

export function useCreateOrderEmergencyCenterDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateOrderEmergencyCenterDocumentCommandValidationErrors>,
    ICreateOrderEmergencyCenterDocumentCommand
  >(`/api/customers/${customerId}/documents/orderemergencycenter`);

  return {
    isLoading,
    createOrderEmergencyCenterDocument: post,
  };
}

export interface IOrderSecurityCompanyDocumentFormDto {
  receiver: string;
  facilityId: number;
  coreSystemId: number;
  agreementId: number;
}

export function useGetOrderSecurityCompanyDocumentForm(customerId: string) {
  const { isLoading, response, get } = useApiGet<IOrderSecurityCompanyDocumentFormDto>(
    `/api/customers/${customerId}/documents/ordersecuritycompanyform`,
    false,
  );

  return {
    isLoading,
    form: response,
    getOrderSecurityCompanyDocumentForm: get,
  };
}

export interface ICreateOrderSecurityCompanyDocumentCommand {
  documentName: string;
  receiver: string | null;
  facilityId: number;
  coreSystemId: number;
  agreementId: number;
}

export interface ICreateOrderSecurityCompanyDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateOrderSecurityCompanyDocumentCommand> {}

export function useCreateOrderSecurityCompanyDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateOrderSecurityCompanyDocumentCommandValidationErrors>,
    ICreateOrderSecurityCompanyDocumentCommand
  >(`/api/customers/${customerId}/documents/ordersecuritycompany`);

  return {
    isLoading,
    createOrderSecurityCompanyDocument: post,
  };
}

export interface ICreateCustomDocumentCommand {
  facilityId: number | null;
  documentName: string;
  customPdfData: string;
}

export interface ICreateCustomDocumentCommandValidationErrors
  extends ValidationErrorResultType<ICreateCustomDocumentCommand> {}

export function useCreateCustomDocument(customerId: string) {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateCustomDocumentCommandValidationErrors>,
    ICreateCustomDocumentCommand
  >(`/api/customers/${customerId}/documents/customDocument`);

  return {
    isLoading,
    createCustomDocument: post,
  };
}
