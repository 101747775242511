import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import {
  useCreateSectionListDocument,
  useGetSectionListDocumentForm,
} from '../../Documents.api';
import { ICoreSystem, useGetFacility } from '../../../../../Facilities/Facilities.api';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import FacilitySelect from '../../../../../Facilities/FacilitySelect/FacilitySelect';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import moment from 'moment';

const SectionList = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, documentName, onDocumentCreated, validateFormState } = props;

  const { getSectionListDocumentForm, isLoading } = useGetSectionListDocumentForm(
    customerId,
    props.facilityId,
  );
  const { getFacility } = useGetFacility(customerId);
  const { isLoading: isCreateLoading, createSectionListDocument } = useCreateSectionListDocument(
    customerId,
  );

  const [receiver, changeReceiver, setReceiver] = useInputState('');
  const [certificateDate, setCertificateDate] = useState<Date>(moment().startOf('day').toDate());
  const [facilityId, setFacilityId] = useState<number | undefined>(
    props.facilityId ? parseInt(props.facilityId) : undefined,
  );
  const [coreSystems, setCoreSystems] = useState<ICoreSystem[]>([]);
  const [coreSystemId, setCoreSystemId] = useState<string>();

  useEffect(() => {
    validateFormState && validateFormState(!!facilityId);
  }, [validateFormState, facilityId]);

  useEffect(() => {
    async function loadForm() {
      const form = await getSectionListDocumentForm();
      if (form) {
        setReceiver(form.receiver);
        setFacilityId(form.facilityId);
        setCoreSystemId(form.coreSystemId.toString());
      }
    }
    loadForm();
  }, [getSectionListDocumentForm, setReceiver]);

  useEffect(() => {
    onLoadingChange(isLoading || isCreateLoading);
  }, [onLoadingChange, isLoading, isCreateLoading]);

  useEffect(() => {
    async function loadFacility() {
      var response = await getFacility(facilityId?.toString());
      setCoreSystems(response.coreSystems);

      if (response.coreSystems.length === 1) {
        setCoreSystemId(response.coreSystems[0].id.toString());
      } else {
        setCoreSystemId(undefined);
      }
    }
    if (facilityId !== undefined) {
      loadFacility();
    }
  }, [facilityId, getFacility]);

  const alarmClass = useMemo(() => {
    return (
      coreSystems.find((coreSystem: ICoreSystem) => coreSystem.id === Number(coreSystemId))?.mainProductRegulation ?? ''
    );
  }, [coreSystems, coreSystemId]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createSectionListDocument({
        documentName: documentName,
        receiver: receiver ? receiver : null,
        certificateDate: certificateDate,
        facilityId: facilityId!,
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [
    createSectionListDocument,
    documentName,
    receiver,
    certificateDate,
    facilityId,
    onDocumentCreated,
  ]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <InputField label="Mottagare" type="text" value={receiver} onChange={changeReceiver} />
      <Datepicker
        label="Datum"
        required
        selected={certificateDate}
        onChange={(d: Date | null) => setCertificateDate(d as Date)}
      />
      <FacilitySelect
        customerId={customerId}
        label="Välj plats"
        required
        selectedFacilityId={facilityId?.toString()}
        onChange={(selected) => {
          setFacilityId(selected ? Number(selected) : undefined);
          setCoreSystemId(undefined);
        }}
      />
    </>
  );
};

export default SectionList;
