import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';
import HtmlView from '../../../../../../Shared/RichTextEditor/HtmlView';
import { ErrorCode, isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import MeetingNotesCommentView from '../MeetingNotesComment/MeetingNotesCommentView';
import { IMeetingNotes, useArchiveMeetingNotes, useDeleteMeetingNotes, useGetMeetingNotes } from '../MeetingNotes.api';
import { useStyles } from './MeetingNotesView.styles';
import MeetingNotesCommentForm from '../MeetingNotesComment/MeetingNotesCommentForm';

export function getNotesHeader(notes: IMeetingNotes) {
  return `${formatDate(notes.date)} av ${notes.createdBy}`;
}

const MeetingNotesView = (props: { noEdit?: boolean; triggerRefetch?: () => void }) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading: isGetLoading, meetingNotes, getMeetingNotes } = useGetMeetingNotes(id);
  const { isLoading: isDeleteLoading, deleteMeetingNotes } = useDeleteMeetingNotes();
  const { isLoading: isArchiveLoading, archiveMeetingNotes } = useArchiveMeetingNotes();
  const { setErrorMessage } = useErrorMessage();
  const isBMS = history.location.pathname.includes('bms');
  const classes = useStyles();

  useEffect(() => {
    getMeetingNotes();
  }, [getMeetingNotes]);

  async function handleArchiveMove() {
    if (isBMS) history.push(`/todos/meetings/archive/${id}`);
    else if (props.noEdit) history.push(`/todos/meetingnotes/${id}/update`);
    else {
      const result = await archiveMeetingNotes(id);
      if (isErrorResponse(result)) {
        setErrorMessage({ message: 'Gick inte flytta till mötesarkiv' });
      } else {
        props.triggerRefetch && props.triggerRefetch();
        history.push(`/todos/meetingnotes`);
      }
    }
  }

  async function handleDelete() {
    const result = await deleteMeetingNotes(id);
    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({
        message: 'Gick inte att ta bort mötesfrågor',
      });
    } else {
      history.push(history.location.pathname.replace(/\/\d+\/update$/, ''));
    }
  }

  return (
    <>
      <Container
        loading={isGetLoading}
        label={meetingNotes && getNotesHeader(meetingNotes)}
        actions={
          isBMS ? (
            <Button color="default" variant="outlined" loading={isArchiveLoading} onClick={() => handleArchiveMove()}>
              'Visa i mötesarkiv'
            </Button>
          ) : (
            <AccessGuard module={ModuleIdentifiers.Todo} accessRights={AccessRights.Write}>
              <Button color="default" variant="outlined" loading={isArchiveLoading} onClick={() => handleArchiveMove()}>
                {props.noEdit ? 'Flytta och redigera' : 'Flytta till arkiv'}
              </Button>
              {!props.noEdit && (
                <>
                  <Button
                    data-testid="delete-meetingnotes-button"
                    color="primary"
                    variant="outlined"
                    loading={isDeleteLoading}
                    onClick={handleDelete}
                  >
                    Ta bort
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.push(`${history.location.pathname}/update`)}
                  >
                    Redigera inlägg
                  </Button>
                </>
              )}
            </AccessGuard>
          )
        }
      >
        <section className={classes.note}>
          {meetingNotes?.title && <Typography variant="h5">{meetingNotes.title}</Typography>}
          <HtmlView value={meetingNotes?.description || ''} />
        </section>

        {meetingNotes && meetingNotes.comments.length > 0 && (
          <>
            <Typography variant="h5" className={classes.commentTitle}>
              Kommentarer
            </Typography>
            <ul className={classes.list}>
              {meetingNotes.comments.map((comment) => (
                <React.Fragment key={comment.id}>
                  <MeetingNotesCommentView comment={comment} refetch={() => getMeetingNotes()} noEdit={props.noEdit} />
                </React.Fragment>
              ))}
            </ul>
          </>
        )}
        <MeetingNotesCommentForm onUpdate={() => getMeetingNotes()}/>
      </Container>
    </>
  );
};

export default MeetingNotesView;
