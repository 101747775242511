import React from 'react';
import ReactSelect, { Props } from 'react-select';
import AsyncSelect, { Props as AsyncProps } from 'react-select/async';
import { styles } from './Select.styles';
import { InputField, IInputFieldProps } from '../InputFields/InputField/InputField';
import InputLabel from '../InputFields/InputLabel/InputLabel';
import { Grid, GridSize, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
export interface ISelectOption {
  value: string;
  label: string;
}

interface ISelectProps {
  inputFieldProps: IInputFieldProps;
  selectProps?: Props<ISelectOption> | AsyncProps<ISelectOption>;
  autoComplete?: boolean;
  tooltip?: string;
  viewOnlyValue?: string;
  customWidth?: GridSize;
  options?: [];
}

function Select(props: ISelectProps) {
  const { autoComplete, tooltip } = props;
  const { label, required, placeholder, viewOnly, fullwidth, customWidth, ...rest } = props.inputFieldProps;
  const labelWithoutSpaces = label?.replace(/ /g, '');

  return (
    <Grid item md={fullwidth ? 12 : customWidth ?? 6} sm={fullwidth ? 12 : customWidth ?? 6} xs={12}>
      <InputField
        {...rest}
        viewOnly={viewOnly}
        label={label}
        fullwidth
        value={viewOnly && props.viewOnlyValue}
        required={required}
        customWidth={props.customWidth}
      >
        {label && <InputLabel label={label} required={required && !viewOnly} id={labelWithoutSpaces} />}
        {tooltip && <Tooltip style={{ position: 'absolute', height: '15px', right: 0, cursor: 'pointer'}} title={tooltip}><InfoOutlined /></Tooltip>}
        {!viewOnly &&
          (autoComplete ? (
            <AsyncSelect
              menuPortalTarget={document.body}
              cacheOptions
              defaultOptions
              styles={styles}
              {...(props.selectProps as AsyncProps<ISelectOption>)}
              aria-labelledby={label ? labelWithoutSpaces : ''}
              aria-label={!label ? placeholder : ''}
              placeholder={placeholder ?? 'Välj...'}
              noOptionsMessage={() => 'Inga alternativ'}
            />
          ) : (
            <ReactSelect
              menuPortalTarget={document.body}
              styles={styles}
              {...props.selectProps}
              placeholder={placeholder ?? 'Välj...'}
              aria-labelledby={label ? labelWithoutSpaces : ''}
              aria-label={!label ? placeholder : ''}
              noOptionsMessage={() => 'Inga alternativ'}
            />
          ))}
      </InputField>
    </Grid>
  );
}

export default Select;
